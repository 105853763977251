var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-md-4 pa-2 text-pre-wrap"},[_c('v-col',{attrs:{"cols":"12","justify":"center"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"color":"secondary","link":"","text":""},on:{"click":_vm.goBack}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1)],1)],1),(this.incident)?_c('div',{staticClass:"glass-block pa-5"},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"2"}},[_c(_vm.dictionary[this.incident.incident_type],{tag:"component"})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"10"}},[_c('h3',[_vm._v(_vm._s(this.incident.impact))])])],1),_c('v-row',{staticClass:"justify-center mb-5"},[_c('v-col',{attrs:{"cols":"10"}},[_c('h5',[_vm._v(" Current Status: "+_vm._s(this.incident.status)+" | Service Impacted: "+_vm._s(this.incident.service_impacted.replaceAll(",", ", "))+" | "),_c('span',[_vm._v("Started on: "+_vm._s(_vm.getDate(this.incident.start_date))+" | ")]),(_vm.incident.status !== this.$constants.STATUS.new)?_c('span',[_vm._v(_vm._s(_vm.getLastUpdate())+" | ")]):_vm._e(),(this.incident.support_case)?_c('span',[_vm._v("Support case: "+_vm._s(this.incident.support_case))]):_vm._e()])])],1),(
          this.incident.bridge &&
          ![
            this.$constants.STATUS.resolved,
            this.$constants.STATUS.prca,
            this.$constants.STATUS.prca_update,
            this.$constants.STATUS.rca ].includes(this.incident.status)
        )?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"3"}},[_vm._v(" LivePerson Bridge ")]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('a',{staticClass:"bridgeLink",attrs:{"href":("" + (this.incident.bridge)),"target":"_blank"}},[_vm._v(" "+_vm._s(this.incident.bridge)+" ")])])],1)],1)]:_vm._e(),(
          [
            this.$constants.STATUS.prca,
            this.$constants.STATUS.prca_update,
            this.$constants.STATUS.rca ].includes(this.incident.status) && this.incident.revision_id
        )?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"3"}},[_vm._v(" "+_vm._s(this.preliminary ? "Preliminary " : "")+"Root Cause Analysis ")]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_vm._v(" A root-cause analysis for this issue has been performed and is available ")]),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"5"}},[_c('span',[(_vm.preliminary)?_c('v-btn',{staticClass:"mx-2",attrs:{"loading":_vm.loading,"small":_vm.$vuetify.breakpoint.mobile,"dense":"","outlined":""},on:{"click":function($event){return _vm.downloadRCA('prca')}}},[_vm._v(" Download PRCA "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-download")])],1):_vm._e(),(!_vm.preliminary)?_c('v-btn',{staticClass:"mx-2",attrs:{"loading":_vm.loading,"small":_vm.$vuetify.breakpoint.mobile,"dense":"","outlined":""},on:{"click":function($event){return _vm.downloadRCA('rca')}}},[_vm._v(" Download RCA "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-download")])],1):_vm._e()],1)])],1)],1)]:_vm._e(),(
          [
            this.$constants.STATUS.prca,
            this.$constants.STATUS.prca_update,
            this.$constants.STATUS.rca ].includes(this.incident.status) && !this.incident.revision_id
        )?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(this.preliminary ? "Preliminary " : "")+"Root Cause Analysis ")]),_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"3"}},[_vm._v(" Root Cause ")]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_vm._v(" "+_vm._s(this.incident.root_cause)+" ")]),_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"3"}},[_vm._v(" Corrective Actions ")]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_vm._v(" "+_vm._s(this.incident.corrective_actions)+" ")])],1)],1)]:(this.incident.status === this.$constants.STATUS.resolved)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"3"}},[_vm._v(" Preliminary RCA ")]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_vm._v(" "+_vm._s(_vm.getSLA())+" ")])],1)],1)]:_vm._e(),(
          [
            this.$constants.STATUS.resolved,
            this.$constants.STATUS.prca,
            this.$constants.STATUS.prca_update,
            this.$constants.STATUS.rca ].includes(this.incident.status) || this.incident.updates.length
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"my-8"}),_c('p',{staticClass:"text-h6 font-weight-bold"},[_vm._v("History for this incident")]),(
            [
              this.$constants.STATUS.resolved,
              this.$constants.STATUS.prca,
              this.$constants.STATUS.prca_update,
              this.$constants.STATUS.rca ].includes(this.incident.status)
          )?_c('div',[_c('v-row',{staticClass:"py-2"},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.getDate(this.incident.end_date))+" ")]),_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$constants.RecoveryColorClass['Full system recovery']},[_vm._v(" Full system recovery ")]),_c('div',[_vm._v(_vm._s(this.incident.resolution_note))])])],1)],1):_vm._e(),(this.incident.updates && this.incident.updates.length)?_c('div',_vm._l((this.incident.updates.slice().reverse()),function(update,index){return _c('v-row',{key:index,staticClass:"py-2"},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.getDate(update.time))+" ")]),_c('v-col',{attrs:{"cols":"9"}},[(update.recovery_status)?_c('div',{staticClass:"font-weight-bold",class:_vm.$constants.RecoveryColorClass[update.recovery_status]},[_vm._v(" "+_vm._s(update.recovery_status)+" ")]):_vm._e(),_c('div',[_vm._v(_vm._s(update.actionTaken))])])],1)}),1):_vm._e()],1):_vm._e()],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }