<template>
  <v-row class="pa-md-4 pa-2 text-pre-wrap">
    <v-col cols="12" justify="center">
      <v-row>
        <v-col cols="12" md="1">
          <v-btn color="secondary" link text @click="goBack">
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-col>
      </v-row>

      <div v-if="this.incident" class="glass-block pa-5">
        <v-row class="justify-center">
          <v-col cols="2">
            <component :is="dictionary[this.incident.incident_type]" />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10">
            <h3>{{ this.incident.impact }}</h3>
          </v-col>
        </v-row>
        <v-row class="justify-center mb-5">
          <v-col cols="10">
            <h5>
              Current Status: {{ this.incident.status }} | Service Impacted:
              {{ this.incident.service_impacted.replaceAll(",", ", ") }} |
              <span
                >Started on: {{ getDate(this.incident.start_date) }} |
              </span>
              <span v-if="incident.status !== this.$constants.STATUS.new"
                >{{ getLastUpdate() }} |
              </span>
              <span v-if="this.incident.support_case"
                >Support case: {{ this.incident.support_case }}</span
              >
            </h5>
          </v-col>
        </v-row>

        <template
          v-if="
            this.incident.bridge &&
            ![
              this.$constants.STATUS.resolved,
              this.$constants.STATUS.prca,
              this.$constants.STATUS.prca_update,
              this.$constants.STATUS.rca,
            ].includes(this.incident.status)
          "
        >
          <v-col cols="12">
            <v-row>
              <v-col class="font-weight-bold" cols="12" md="3">
                LivePerson Bridge
              </v-col>
              <v-col cols="12" md="9">
                <a
                  :href="`${this.incident.bridge}`"
                  class="bridgeLink"
                  target="_blank"
                >
                  {{ this.incident.bridge }}
                </a>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template
          v-if="
            [
              this.$constants.STATUS.prca,
              this.$constants.STATUS.prca_update,
              this.$constants.STATUS.rca,
            ].includes(this.incident.status) && this.incident.revision_id
          "
        >
          <v-col cols="12">
            <v-row justify="center">
              <v-col class="font-weight-bold" cols="12" md="3">
                {{ this.preliminary ? "Preliminary " : "" }}Root Cause Analysis
              </v-col>
              <v-col cols="12" md="9">
                A root-cause analysis for this issue has been performed and is
                available
              </v-col>
              <v-col class="ma-0 pa-0" cols="12" md="5">
                <span>
                  <v-btn
                    v-if="preliminary"
                    :loading="loading"
                    :small="$vuetify.breakpoint.mobile"
                    class="mx-2"
                    dense
                    outlined
                    @click="downloadRCA('prca')"
                  >
                    Download PRCA
                    <v-icon class="ml-2">mdi-download</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!preliminary"
                    :loading="loading"
                    :small="$vuetify.breakpoint.mobile"
                    class="mx-2"
                    dense
                    outlined
                    @click="downloadRCA('rca')"
                  >
                    Download RCA
                    <v-icon class="ml-2">mdi-download</v-icon>
                  </v-btn>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template
          v-if="
            [
              this.$constants.STATUS.prca,
              this.$constants.STATUS.prca_update,
              this.$constants.STATUS.rca,
            ].includes(this.incident.status) && !this.incident.revision_id
          "
        >
          <v-col cols="12">
            <v-row justify="center">
              <v-col class="font-weight-bold" cols="12">
                {{ this.preliminary ? "Preliminary " : "" }}Root Cause Analysis
              </v-col>
              <v-col class="font-weight-bold" cols="12" md="3">
                Root Cause
              </v-col>
              <v-col cols="12" md="9">
                {{ this.incident.root_cause }}
              </v-col>
              <v-col class="font-weight-bold" cols="12" md="3">
                Corrective Actions
              </v-col>
              <v-col cols="12" md="9">
                {{ this.incident.corrective_actions }}
              </v-col>
            </v-row>
          </v-col>
        </template>

        <template
          v-else-if="this.incident.status === this.$constants.STATUS.resolved"
        >
          <v-col cols="12">
            <v-row>
              <v-col class="font-weight-bold" cols="12" md="3">
                Preliminary RCA
              </v-col>
              <v-col cols="12" md="9">
                {{ getSLA() }}
              </v-col>
            </v-row>
          </v-col>
        </template>

        <v-col
          v-if="
            [
              this.$constants.STATUS.resolved,
              this.$constants.STATUS.prca,
              this.$constants.STATUS.prca_update,
              this.$constants.STATUS.rca,
            ].includes(this.incident.status) || this.incident.updates.length
          "
          cols="12"
        >
          <v-divider class="my-8"></v-divider>
          <p class="text-h6 font-weight-bold">History for this incident</p>
          <div
            v-if="
              [
                this.$constants.STATUS.resolved,
                this.$constants.STATUS.prca,
                this.$constants.STATUS.prca_update,
                this.$constants.STATUS.rca,
              ].includes(this.incident.status)
            "
          >
            <v-row class="py-2">
              <v-col cols="3">
                {{ getDate(this.incident.end_date) }}
              </v-col>
              <v-col cols="9">
                <div
                  :class="$constants.RecoveryColorClass['Full system recovery']"
                  class="font-weight-bold"
                >
                  Full system recovery
                </div>
                <div>{{ this.incident.resolution_note }}</div>
              </v-col>
            </v-row>
          </div>
          <div v-if="this.incident.updates && this.incident.updates.length">
            <v-row
              v-for="(update, index) in this.incident.updates.slice().reverse()"
              :key="index"
              class="py-2"
            >
              <v-col cols="3">
                {{ getDate(update.time) }}
              </v-col>
              <v-col cols="9">
                <div
                  v-if="update.recovery_status"
                  :class="$constants.RecoveryColorClass[update.recovery_status]"
                  class="font-weight-bold"
                >
                  {{ update.recovery_status }}
                </div>
                <div>{{ update.actionTaken }}</div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import degradation from "@/components/tooltipIcons/degradation";
import thirdparty from "@/components/tooltipIcons/thirdparty";
import downtime from "@/components/tooltipIcons/downtime";
import maintenance from "@/components/tooltipIcons/maintenanceIcon";
import suspected from "@/components/tooltipIcons/suspected";

export default {
  name: "Incident",
  components: {
    degradation,
    thirdparty,
    downtime,
    maintenance,
    suspected,
  },

  data: () => ({
    dictionary: {
      Degradation: degradation,
      "3rd Party": thirdparty,
      Downtime: downtime,
      Maintenance: maintenance,
      Suspected: suspected,
    },
    preliminary: false,
    incident: null,
    loading: false,
  }),

  async created() {
    try {
      this.setLoading(true);
      this.incident = await this.fetchIncidentData(this.interruptionId);
      this.preliminary = ["PRCA", "PRCA-UPDATE"].includes(this.incident.status);
    } catch (err) {
      console.error(err);
      await this.showPopup("Error loading incident data");
    } finally {
      this.setLoading(false);
    }
  },
  computed: {
    ...mapGetters("Site", ["getTimeZone"]),
    interruptionId() {
      return this.$route.params.id || null;
    },
    siteId() {
      return this.$route.params.site || null;
    },
    timezone() {
      return this.getTimeZone;
    },
  },

  methods: {
    ...mapActions("Site", ["fetchIncidentData"]),
    ...mapActions("Tools", ["showPopup"]),
    ...mapMutations("Tools", ["setLoading"]),
    getDate(dateTs) {
      return this.$moment
        .tz(dateTs * 1000, this.timezone)
        .format("YYYY-MM-DD hh:mm A");
    },

    getLastUpdate() {
      let time;
      switch (this.incident.status) {
        case "PRCA":
        case "PRCA-UPDATE":
        case "RCA":
        case "Resolved":
          time = this.getDate(this.incident.end_date);
          return `Ended on: ${time}`;
        case "In Progress":
          time = this.getDate(
            this.incident.updates.length
              ? this.incident.updates[this.incident.updates.length - 1].time
              : this.incident.start_date
          );
          return `Last updated at: ${time}`;
      }
    },

    async downloadRCA(type) {
      try {
        this.loading = true;
        const rcaId =
          type === "rca" ? this.incident.rca.id : this.incident.prca.id;
        const payload = {
          id: rcaId,
          timezone: this.timezone,
        };
        await this.$store.dispatch("Site/downloadRCA", payload);
      } catch (err) {
        await this.showPopup("Error downloading incident rca");
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      switch (this.$route.name) {
        case "Current Incident":
          return this.$router.push(`/current/${this.siteId}`);
        case "History Incident":
          return this.$router.push(`/history/${this.siteId}`);
        default:
          return this.$router.push(`/dashboard/${this.siteId}`);
      }
    },
    getSLA() {
      if (this.incident.rca_sla === "NO RCA.") {
        return "No RCA Planned";
      }
      const sla =
        this.incident.severity === "SEV1"
          ? 2
          : this.incident.severity === "SEV2"
          ? 3
          : 0;
      return sla > 0
        ? `Within ${sla} business days of resolution`
        : "No RCA Planned";
    },
  },
};
</script>

<style lang="scss" scoped>
.bridgeLink {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
